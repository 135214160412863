import BackgroundImage from "gatsby-background-image";
import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import parse from "html-react-parser";
import Navigation from "../components/navigation";
import { Helmet } from "react-helmet";

export default function BlogPost({ data }) {
	const post = data?.wpPost;
	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog${post.uri}`,
					id: `${siteUrl}/blog${post.uri}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.title}
				description={post.blogSeo.metaDescription}
				openGraph={{
					url: `${siteUrl}/blog/${post.uri}`,
					title: `${post.title}`,
					description: post.blogSeo.metaDescription,
					type: "article",
					images: [
						{
							url: `${post.blogFields?.featuredImage.node?.localFile.publicURL}`,
							width: `${post.blogFields?.featuredImage.node?.localFile.childImageSharp.original.width}`,
							height: `${post.blogFields?.featuredImage.node?.localFile.childImageSharp.original.height}`,
							alt: `${post.title}`,
						},
					],
				}}
			/>
			<section
				style={{
					background:
						"transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
					opacity: 1,
				}}
			>
				<Navigation background="none" />
				<Container>
					{" "}
					<Row>
						<Col>
							<h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
								{post.title}
							</h1>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<div className="blog-post-content">{parse(post.content)}</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
}

export const blogData = graphql`
	query ($id: String!) {
		wpPost(id: { eq: $id }) {
			id
			slug
			title
			uri
			excerpt
			content
			blogSeo {
				metaDescription
			}
			blogFields {
				featuredImage {
					node {
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									placeholder: BLURRED
									width: 980
									layout: CONSTRAINED
								)
							}
						}
					}
					
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
